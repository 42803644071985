@import url("https://fonts.googleapis.com/css2?family=Cairo+Play:wght@200..1000&family=Cairo:wght@200..1000&display=swap");

.ar .main-menu ul ul {
  background: #c9ac52 none repeat scroll 0 0;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: right;
  transform: scale(0);
  transform-origin: 0 0 0;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  z-index: 99999;
  top: 94px;
}
.en .main-menu ul ul {
  background: #c9ac52 none repeat scroll 0 0;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: left;
  transform: scale(0);
  transform-origin: 0 0 0;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  z-index: 99999;
  top: 94px;
}
.en .why-choose-us-before .sec-title {
  text-align: left;
  margin: 0 0 40px 0;
}
.ar .why-choose-us-before .sec-title {
  text-align: right;
  margin: 0 0 40px 0;
}
html,
body {
  height: 100%;
  font-size: 15px;
  color: #424242;
  font-family: "Cairo", sans-serif;
  vertical-align: baseline;
  line-height: 26px;
  background: #fff;
  text-align: left !important;
}
html,
body {
  height: 100%;
  font-size: 15px;
  color: #424242;
  font-family: "Cairo", sans-serif;
  vertical-align: baseline;
  line-height: 26px;
  background: #fff;
  text-align: left !important;
}

.ar .menu-icon {
  display: inline-block;
  padding: 34px 0;
  position: absolute;
  left: 0;
  top: 0;
}
.en .menu-icon {
  display: inline-block;
  padding: 34px 0;
  position: absolute;
  right: 0;
  top: 0;
}
.en .menu-icon:hover .search-forum {
  visibility: visible;
  transition: all 0.4s ease 0s;
  transform: scale(1);
  opacity: 1;
}
.search-forum {
  display: inline-block;
  left: -240px;
  opacity: 0;
  position: absolute;
  top: 94px;
  transition: all 0.4s ease 0s;
  visibility: hidden;
  z-index: 2;
  transform: scale(0);
}
.ar .menu-icon:hover .search-forum {
  visibility: visible;
  transition: all 0.4s ease 0s;
  transform: scale(1);
  opacity: 1;
}
.search-forum {
  display: inline-block;
  right: -240px;
  opacity: 0;
  position: absolute;
  top: 94px;
  transition: all 0.4s ease 0s;
  visibility: hidden;
  z-index: 2;
  transform: scale(0);
}
.ar .main-menu > ul {
  float: left;
  list-style: none outside none;
  margin: 0;
  padding-left: 40px;
  position: relative;
}
.en .main-menu > ul {
  float: right;
  list-style: none outside none;
  margin: 0;
  padding-right: 40px;
  position: relative;
}
.ar .why-choose-us-before::before {
  background-color: #ddd;
  content: "";
  height: 120%;
  right: -20px;
  position: absolute;
  top: 0;
  width: 5000px;
  z-index: -1;
}
.en .why-choose-us-before::before {
  background-color: #ddd;
  content: "";
  height: 120%;
  left: -20px;
  position: absolute;
  top: 0;
  width: 5000px;
  z-index: -1;
}
.ar .about-desc {
  display: inline-block;
  width: 100%;
  padding-left: 40px;
}
.en .about-desc {
  display: inline-block;
  width: 100%;
  padding-right: 40px;
}
.ar .about-sec .sec-title {
  margin: 0;
  text-align: right;
}
.en .about-sec .sec-title {
  margin: 0;
  text-align: left;
}
.ar .about-us-img::before {
  position: absolute;
  content: "";
  background: #c9ac52;
  width: 100%;
  height: 100%;
  right: -25px;
  top: 30px;
  z-index: -1;
}
.en .about-us-img::before {
  position: absolute;
  content: "";
  background: #c9ac52;
  width: 100%;
  height: 100%;
  left: -25px;
  top: 30px;
  z-index: -1;
}
.en .about-us-img {
  text-align: right !important;
}
.ar .about-us-img {
  text-align: left !important;
}
.ar .hd-lft li {
  color: #f9f9f9;
  display: inline-block;
  padding-left: 20px;
  position: relative;
  font-size: 14px;
}
.en .hd-lft li {
  color: #f9f9f9;
  display: inline-block;
  padding-right: 20px;
  position: relative;
  font-size: 14px;
}
.ar .hd-rgt li a {
  color: #f9f9f9;
  font-size: 14px;
  padding-right: 8px;
  position: relative;
  text-transform: capitalize;
  transition: all 0.36s ease-out 0s;
}
.en .hd-rgt li a {
  color: #f9f9f9;
  font-size: 14px;
  padding-right: 8px;
  position: relative;
  text-transform: capitalize;
  transition: all 0.36s ease-out 0s;
}
.en .hd-lft li i {
  margin-right: 10px;
  color: #c9ac52;
}
.ar .hd-lft li i {
  margin-left: 10px;
  color: #c9ac52;
}
.ar .slider-wraper {
  width: 700px;
  overflow: hidden;
  padding: 20px 10px;
  position: relative;
  z-index: 999;
  text-align: right;
}
.en .slider-wraper {
  width: 700px;
  overflow: hidden;
  padding: 20px 10px;
  position: relative;
  z-index: 999;
  text-align: left;
}
.ar .counting-inner {
  display: inline-block;
  padding-left: 80px;
}
.en .counting-inner {
  display: inline-block;
  padding-right: 80px;
}
.ar .service2-sec .sec-title {
  margin: 0 0 60px;
  text-align: right;
  color: #fff;
}
.en .service2-sec .sec-title {
  margin: 0 0 60px;
  text-align: left;
  color: #fff;
}
.en .service2-icon {
  width: 40px;
  margin-right: 10px;
  margin-top: 10px;
}
.ar .service2-icon {
  width: 40px;
  margin-left: 10px;
  margin-top: 10px;
}
.ar .testimonial-text .sec-title h1 {
  color: #fff;
  text-align: right;
}
.en .testimonial-text .sec-title h1 {
  color: #fff;
  text-align: left;
}
.en .faq-sec {
  padding-right: 50px;
  padding-top: 100px;
}
.ar .faq-sec {
  padding-left: 50px;
  padding-top: 100px;
}
.ar .appointment-form::before {
  background-color: #020e2a;
  content: "";
  height: 120%;
  right: -35px;
  position: absolute;
  top: 0;
  width: 5000px;
  z-index: -9;
  opacity: 0.92;
}
.en .appointment-form::before {
  background-color: #020e2a;
  content: "";
  height: 120%;
  left: -35px;
  position: absolute;
  top: 0;
  width: 5000px;
  z-index: -9;
  opacity: 0.92;
}
.ar .appointment-form fieldset {
  margin-left: -15px;
}
.en .appointment-form fieldset {
  margin-right: -15px;
}

.ar .iconsvg {
  color: #c9ac52;
  margin-left: 5px;
}
.en .iconsvg {
  color: #c9ac52;
  margin-right: 5px;
}
.ar .footer-widget-menu li:before {
  color: #c9ac52;
  content: "\F101";
  font-family: fontawesome;
  font-size: 14px;
  right: -4px;
  position: absolute;
  top: 0;
  rotate: 180deg;
}
.en .footer-widget-menu li:before {
  color: #c9ac52;
  content: "\F101";
  font-family: fontawesome;
  font-size: 14px;
  left: -4px;
  position: absolute;
  top: 0;
  /* rotate: -180deg; */
}
.ar .footer-widget-menu li a {
  color: #ededed;
  display: inline-block;
  font-size: 14px;
  padding-bottom: 10px;
  padding-right: 14px;
  text-transform: capitalize;
}
.en .footer-widget-menu li a {
  color: #ededed;
  display: inline-block;
  font-size: 14px;
  padding-bottom: 10px;
  padding-left: 14px;
  text-transform: capitalize;
}
.en .service2-inner::before {
  position: absolute;
  content: "";
  background: #ddd;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.4s ease 0s;
  top: 0;
  left: 0;
}
.ar .service2-inner::before {
  position: absolute;
  content: "";
  background: #ddd;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.4s ease 0s;
  top: 0;
  right: 0;
}
.ar .appointment-form h2 {
  text-align: right;
}
.en .appointment-form h2 {
  text-align: left;
}
.ar .btn {
  text-align: right;
}
.en .btn {
  text-align: left;
}
.ar .navtitle {
  text-align: right;
}
.en .navtitle {
  text-align: left;
}

.ar .hd-rgt {
  text-align: left;
}
.en .hd-rgt {
  text-align: right;
}
.ar .testimonial-text {
  color: #fff;
  text-align: right !important;
}
.en .testimonial-text{
  color: #fff;
  
  text-align: left !important;
}
 .ar  .service2-details{
  text-align: right;
 }
 .en  .service2-details{
  text-align: left;
 }
 .ar .faq-sec{
 text-align: right;
 }
 .en .faq-sec{
 text-align: left;
 }
 .ar .appoitment-area .appointment-form .sngl-field .select-arrow:before {
  color: #fff;
  content: "\F107 ";
  font-family: fontawesome;
  position: absolute;
  left: 15px;
  top: 10px;
}
 .en .appoitment-area .appointment-form .sngl-field .select-arrow:before {
  color: #fff;
  content: "\F107 ";
  font-family: fontawesome;
  position: absolute;
  right: 15px;
  top: 10px;
}